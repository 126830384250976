<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="rgb(255 255 255)"
      height="65"
      class="elevation-0"
    >
      <base-img
        :src="require('@/assets/logo-orion.png')"
        class="mt-0 cursor"
        contain
        max-width="200"
        style="margin-left: -24px; margin-top: 24px; height: 55px"
        @click="URL('beranda')"
      />
      <v-spacer />

      <div>
        <v-tabs
          v-model="active_tab"
          class="hidden-sm-and-down"
          optional
          background-color="rgb(255 255 255)"
          color="#FFFFFF"
        >
          <v-tab
            v-for="data in items"
            :key="data.id"
            @click="data.child.length === 0 ? URL(data.id) : ''"
            :ripple="false"
            class="font-weight-bold text-capitalize"
            min-width="96"
            style="color: #114e87"
            text
          >
            <v-menu
              offset-y
              open-on-hover
              :close-on-content-click="true"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="secondary" left v-if="data.id === 'login'">
                  mdi-shield-account-variant
                </v-icon>
                <span v-bind="attrs" v-on="on"
                  >{{ data.title }}
                  <v-icon
                    v-if="data.child.length > 0"
                    style="color: rgb(255 255 255)"
                    >mdi-menu-down</v-icon
                  ></span
                >
              </template>
              <v-list v-if="data.child.length > 0" dense class="mt-4">
                <v-list-item
                  v-for="(item, index) in data.child"
                  :key="index"
                  @click="URL(item.id)"
                >
                  <v-list-item-title
                    ><v-icon>{{ item.icon }}</v-icon>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        icon
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" @close="drawer = false" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },

  data: () => ({
    drawer: null,
    items: [
      { id: "beranda", title: "Beranda", child: [] },
      {
        id: "mekanisme-penjaminan",
        title: "Mekanisme Penjaminan",
        child: [
          {
            id: "proses-bisnis",
            title: "Proses Bisnis",
            icon: "mdi-clipboard-flow",
          },
          {
            id: "mekanisme-penjaminan",
            title: "Mekanisme dan Proses Persetujuan",
            icon: "mdi-alpha-m-box",
          },
          {
            id: "manfaat-penjaminan",
            title: "Manfaat Penjaminan",
            icon: "mdi-bridge",
          },
          {
            id: "tujuan-penjaminan",
            title: "Tujuan Perusahaan Penjaminan",
            icon: "mdi-shield-check",
          },
          // {id: 'faq', title: 'FAQ', icon:'mdi-message-question-outline'},
        ],
      },
      {
        id: "tentang",
        title: "Tentang Kami",
        child: [
          { id: "tentang-kami", title: "Visi Misi", icon: "mdi-bullseye" },
          {
            id: "struktur-perusahaan",
            title: "Struktur dan Manajemen",
            icon: "mdi-sitemap",
          },
          {
            id: "sejarah-orionpenjaminan",
            title: "Sejarah",
            icon: "mdi-domain",
          },
          { id: "produk", title: "Produk", icon: "mdi-package" },
          {
            id: "artikel-dan-informasi-terkini",
            title: "Artikel dan Informasi Terkini",
            icon: "mdi-newspaper-variant-outline",
          },
          // {id: 'penghargaan-perusahaan', title: 'Penghargaan', icon:'mdi-trophy-outline'},
          { id: "laporan", title: "Laporan", icon: "mdi-finance" },
        ],
      },
      // { id: "produk", title: "Produk", child: [] },
      {
        id: "human-capital",
        title: "Human Capital",
        child: [
          {
            id: "core-values",
            title: "Core Values",
            icon: "mdi-badge-account",
          },
          { id: "karir", title: "Karir", icon: "mdi-briefcase" },
        ],
      },
      {
        id: "layanan",
        title: "Layanan",
        child: [
          // { id: 'pengaduan', title: 'Pengaduan Peserta', icon:'mdi-format-quote-close-outline' },
          {
            id: "jaringan",
            title: "Jaringan",
            icon: "mdi-store-marker-outline",
          },
          {
            id: "client",
            title: "Mitra Penerima Jaminan",
            icon: "mdi-account-supervisor",
          },
          { id: "agent", title: "Agen", icon: "mdi-briefcase-account" },
        ],
      },
      { id: "kontak-kami", title: "Kontak", child: [] },
      // { id: 'wbs', title: 'Whistle Blowing', child: [] },
      // { id: 'karir', title: 'Karir', child: [] },
      // { id: 'login', title: 'AkunKu', child: [] },
    ],
    ShowMenu: false,
  }),
  computed: {
    active_tab() {
      return this.$route.meta.tabs;
    },
  },
  methods: {
    URL(id) {
      if (id !== "") {
        this.$router.push({ name: id }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
